<template>
    <v-card elevation="4">
        <v-card-title>
            <span class="text-subtitle-2">{{title}}</span>
        </v-card-title>
        <v-card-text>
            <v-row class="mb-3 flex-nowrap">
                <v-avatar size="28" class="mx-3">
                    <img :src="avatar" alt="">
                </v-avatar>
                <div class="d-flex align-center pr-3 break">
                    {{name}}
                </div>
            </v-row>
            <v-row class="mb-3 flex-nowrap">
                <v-icon class="mx-3">mdi-phone</v-icon>
                <div v-html="phone" class="d-flex flex-column pr-3 break"></div>
            </v-row>
            <v-row class="flex-nowrap mb-0">
                <v-icon class="mx-3">mdi-email-outline</v-icon>
                <div v-html="mail" class="d-flex align-center pr-3 break"></div>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
    export default {

        props: [
            'title',
            'avatar',
            'name',
            'phone',
            'mail'
        ]

    }
</script>

<style>

</style>
<template>
    <v-container>

        <div class="bg"></div>

        <v-row class="my-5">
            <v-col cols="12" md="6" xl="4" offset-xl="2">
                <person-card
                    title="Ваш менеджер"
                    :avatar="managerAvatar"
                    :name="managerName"
                    :phone="managerPhone"
                    :mail="managerMail"
                ></person-card>
            </v-col>

            <v-col cols="12" md="6" xl="4">
                <person-card
                    title="Ответственные лица с вашей стороны"
                    :avatar="contactAvatar"
                    :name="contactName"
                    :phone="contactPhone"
                    :mail="contactMail"
                ></person-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import personCard from '@/components/actual/PersonCard'
import {mapState} from 'vuex'

export default {

    components: {
        personCard
    },

    computed: {
        ...mapState({
            manager: state => state.main.manager,
            contact: state => state.main.contact
        }),

        managerPhone() {
            return this.phone('manager')
        },

        managerAvatar() {
            return this.avatar('manager')
        },

        managerName() {
            return this.name('manager')
        },

        managerMail() {
            return this.mail('manager')
        },

        contactPhone() {
            return this.phone('contact')
        },

        contactAvatar() {
            return this.avatar('contact')
        },

        contactName() {
            return this.name('contact')
        },

        contactMail() {
            return this.mail('contact')
        }
    },

    methods: {

        phone(person) {
            if (this[person].phones !== undefined && this[person].phones.phone !== undefined) {
                if (Array.isArray(this[person].phones.phone)) {
                    let phones = ''
                    this[person].phones.phone.forEach(phone => {
                        if (typeof phone.number === 'string')
                            phones += '<a href="tel:' + phone.number + '">' + phone.number + '</a>'
                    })
                    return phones
                }

                let phone = ''
                if (typeof this[person].phones.phone.number === 'string') {
                    let href = this[person].phones.phone.number.replaceAll(/[ \-()]/g, '')
                    phone += '<a href="tel:' + href + '">' + this[person].phones.phone.number + '</a>'
                }
                return phone
            }
            return ''
        },

        avatar(person) {
            return this[person].userPic ? this[person].userPic : ''
        },

        name(person) {
            let name = ''
            if (typeof this[person].name === 'string')
                name += this[person].name + ' '

            if (typeof this[person].lastName === 'string')
                name += this[person].lastName

            return name
        },

        mail(person) {
            let email = ''
            if (typeof this[person].email === 'string' && this[person].email !== '')
                email = '<a href="mailto:' + this[person].email + '">' + this[person].email + '</a>'
            return email
        }

    }

}
</script>